@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: Crunold;
    font-weight: 400;
    src: url('../fonts/CRUNOLD-REGULAR.woff') format('woff');
  }
  @font-face {
    font-family: Crunold;
    font-weight: 500;
    src: url('../fonts/CRUNOLD-REGULAR.woff') format('woff');
  }
}
